import CalibreWebLightEot from './CalibreWeb-Light.eot'
import CalibreWebLightWoff from './CalibreWeb-Light.woff'
import CalibreWebLightWoff2 from './CalibreWeb-Light.woff2'
import CalibreWebRegularEot from './CalibreWeb-Regular.eot'
import CalibreWebRegularWoff from './CalibreWeb-Regular.woff'
import CalibreWebRegularWoff2 from './CalibreWeb-Regular.woff2'
import CalibreWebSemiboldEot from './CalibreWeb-Semibold.eot'
import CalibreWebSemiboldWoff from './CalibreWeb-Semibold.woff'
import CalibreWebSemiboldWoff2 from './CalibreWeb-Semibold.woff2'
import CalibreWebBoldEot from './CalibreWeb-Bold.eot'
import CalibreWebBoldWoff from './CalibreWeb-Bold.woff'
import CalibreWebBoldWoff2 from './CalibreWeb-Bold.woff2'
import CalibreWebBlackEot from './CalibreWeb-Black.eot'
import CalibreWebBlackWoff from './CalibreWeb-Black.woff'
import CalibreWebBlackWoff2 from './CalibreWeb-Black.woff2'

export default `
  @font-face {
    font-family: "Calibre";
    font-weight: 300;
    src: url(${CalibreWebLightEot}) format("eot"),
      url(${CalibreWebLightWoff}) format("woff"),
      url(${CalibreWebLightWoff2}) format("woff2");
  }
  @font-face {
    font-family: "Calibre";
    font-weight: 400;
    src: url(${CalibreWebRegularEot}) format("eot"),
      url(${CalibreWebRegularWoff}) format("woff"),
      url(${CalibreWebRegularWoff2}) format("woff2");
  }
  @font-face {
    font-family: "Calibre";
    font-weight: 600;
    src: url(${CalibreWebSemiboldEot}) format("eot"),
      url(${CalibreWebSemiboldWoff}) format("woff"),
      url(${CalibreWebSemiboldWoff2}) format("woff2");
  }
  @font-face {
    font-family: "Calibre";
    font-weight: 700;
    src: url(${CalibreWebBoldEot}) format("eot"),
      url(${CalibreWebBoldWoff}) format("woff"),
      url(${CalibreWebBoldWoff2}) format("woff2");
  }
  @font-face {
    font-family: "Calibre";
    font-weight: 900;
    src: url(${CalibreWebBlackEot}) format("eot"),
      url(${CalibreWebBlackWoff}) format("woff"),
      url(${CalibreWebBlackWoff2}) format("woff2");
  }
`
