import React from 'react'
import { GatsbyLinkProps } from 'gatsby-link'
import { Group } from 'reakit/Group'
import { Button } from 'reakit/Button'
import { Box, Flex } from 'rebass/styled-components'

import { MenuProps, MenuItem } from './Menu'

interface SidebarMenuProps extends MenuProps {
  Link: React.ComponentClass<GatsbyLinkProps<any>>
}

export const SidebarMenu = ({
  menuItems,
  pathname,
  Link,
}: SidebarMenuProps): React.ReactElement => {
  const isActive = (item: MenuItem) =>
    item.exact ? pathname === item.path : pathname.startsWith(item.path)
  const defaultMenuItem: MenuItem = { name: 'default', path: '/', exact: false }
  const activeItem =
    menuItems.find((item: MenuItem) => isActive(item)) || defaultMenuItem

  return (
    <Flex as={Group} bg="primary.main" flexDirection="column">
      {menuItems.map(
        (item): React.ReactElement => {
          return (
            <Box
              textAlign="center"
              key={item.path}
              as={Link}
              to={item.path}
              sx={{
                color: 'primary.text',
                backgroundColor: 'primary.main',
                fontSize: 2,
                fontFamily: 'body',
                fontWeight: 'black',
                marginTop: '2rem',
                textDecoration: 'none',
              }}
            >
              {item.name}
            </Box>
          )
        }
      )}
    </Flex>
  )
}

export default SidebarMenu
