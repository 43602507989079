import { graphql, Link, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'
import { Box, BoxProps, Flex, FlexProps, Text } from 'rebass/styled-components'
import { Container, ExternalLinkWhite } from './base'
import CustomerJoinLink, { CUSTOMER_JOIN_URL } from './CustomerJoinLink'

function HeaderLogoContainer(props: BoxProps): React.ReactElement {
  return <Box {...props} />
}

export function HomeLogoLink(): React.ReactElement {
  const data = useStaticQuery(graphql`
    query {
      HeaderLogo: file(relativePath: { eq: "images/HeaderLogo.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      UsingChoiceHeart: file(
        relativePath: { eq: "images/UsingChoiceHeart.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <HeaderLogoContainer width={[4 / 5, 1, 1]} py={[4, 2, 3]} px={2}>
      <Link to="/">
        <Img fluid={data.HeaderLogo.childImageSharp.fluid} />
      </Link>
      <Flex>
        <Container width={[2 / 7, 2 / 5, 2 / 5]}>
          <Box width={[1, 1, 6 / 10]} pt={[3, 3, 3]}>
            <Img fluid={data.UsingChoiceHeart.childImageSharp.fluid} />
          </Box>
        </Container>
        <Container width={[1, 1 / 2, 1]}>
          <Box
            color="primary.text"
            width={1}
            fontFamily="body"
            ml={['0.5rem', '0.5rem', -2]}
            mt={['-1rem', '-1rem', '-2rem']}
          >
            <Text
              fontSize={[1, 2, 4]}
              fontWeight="bold"
              pt={['1.8rem', '1.8rem', '2.8rem']}
            >
              LIVE!
            </Text>
            <ExternalLinkWhite href={CUSTOMER_JOIN_URL}>
              <Text
                fontWeight="regular"
                sx={{
                  textDecoration: 'underline',
                }}
                fontSize={-1}
              >
                Join the waitlist for early access
              </Text>
            </ExternalLinkWhite>
          </Box>
        </Container>
      </Flex>
    </HeaderLogoContainer>
  )
}
