import { graphql, Link, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'
import { Box, BoxProps, Flex } from 'rebass/styled-components'
import { Portal } from 'reakit/Portal'
import {
  useDialogState,
  Dialog,
  DialogDisclosure,
  DialogBackdrop,
} from 'reakit'
import { FaBars } from 'react-icons/fa'
import { HeaderJoinMovementButton } from './Header'

const SidebarDialog = (props: BoxProps) => (
  <Box
    as={Dialog}
    alignItems="center"
    sx={{
      position: 'fixed',
      left: 0,
      top: 0,
      width: '100vw',
      zIndex: 999,
      transition: 'transform .5s ease-in-out',
      transform: 'translateX(0)',
      '&.hidden': {
        transform: 'translateX(-100%)',
      },
      '&.before-visible': {
        transform: 'translateX(-100vw)',
      },
    }}
    {...props}
  />
)

const SidebarDisclosure = (props: BoxProps) => (
  <Box
    as={DialogDisclosure}
    color="primary.text"
    backgroundColor="transparent"
    sx={{
      position: 'fixed',
      top: 3,
      right: 3,
      height: '3rem',
      width: '3rem',
      zIndex: 1,
      borderColor: 'primary.main',
      border: 0,
    }}
    {...props}
  />
)

const Backdrop = (props: BoxProps) => (
  <Box
    as={DialogBackdrop}
    sx={{
      backgroundColor: 'primary.main',
      position: 'fixed',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      zIndex: 999,
    }}
    {...props}
  />
)

interface Props {
  shouldRenderSidebar: boolean
  children: React.ReactNode
}

const Sidebar = (props: Props): React.ReactElement => {
  const data = useStaticQuery(graphql`
    query {
      hand: file(relativePath: { eq: "images/hand.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      HeaderLogo: file(relativePath: { eq: "images/HeaderLogo.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const { shouldRenderSidebar, children } = props

  // eslint-disable-next-line @typescript-eslint/camelcase
  const dialog = useDialogState({ unstable_animated: true })

  const [className, setClassName] = React.useState('')

  React.useEffect(() => {
    setClassName(
      !dialog.visible && !dialog.unstable_animating ? 'before-visible' : ''
    )
  }, [dialog.visible, dialog.unstable_animating])

  if (!shouldRenderSidebar) return null

  return (
    <Box>
      {!dialog.visible && (
        <SidebarDisclosure {...dialog}>
          <FaBars />
        </SidebarDisclosure>
      )}
      <Portal>
        <Backdrop {...dialog} />
      </Portal>
      <SidebarDialog
        {...dialog}
        aria-label="sidebar menu"
        className={className}
        preventBodyScroll
        hideOnClickOutside
        hideOnEsc
        p={4}
      >
        {children}
        <Flex alignItems="center" flexDirection="column">
          <Box width={1 / 2} mt={'3rem'} px={4}>
            <Link to="/">
              <Img fluid={data.HeaderLogo.childImageSharp.fluid} />
            </Link>
          </Box>
          <Box width={[1 / 10]} pt={5}>
            <Img fluid={data.hand.childImageSharp.fluid} />
          </Box>
          <Box width={3 / 4} mt={4}>
            <HeaderJoinMovementButton />
          </Box>
        </Flex>
      </SidebarDialog>
    </Box>
  )
}

export default Sidebar
