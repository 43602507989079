import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'
import { Box, Button } from 'rebass/styled-components'
import { ExternalLink } from './base'

function SocialLinkWrapper(props: any): React.ReactElement {
  return (
    <Button bg="transparent" width={[1 / 6, 1 / 5, 1 / 10]} px={2}>
      <ExternalLink {...props}>
        <Img {...props} />
      </ExternalLink>
    </Button>
  )
}

export const fluidImage3 = graphql`
  fragment fluidImage3 on File {
    childImageSharp {
      fluid(maxWidth: 1000) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`
export function SocialLinkBadges(): React.ReactElement {
  const data = useStaticQuery(graphql`
    query {
      FooterInstagram: file(
        relativePath: { eq: "images/FooterInstagram.png" }
      ) {
        ...fluidImage3
      }
      FooterTwitter: file(relativePath: { eq: "images/FooterTwitter.png" }) {
        ...fluidImage3
      }
      FooterFacebook: file(relativePath: { eq: "images/FooterFacebook.png" }) {
        ...fluidImage3
      }
      FooterMedium: file(relativePath: { eq: "images/FooterMedium.png" }) {
        ...fluidImage3
      }
    }
  `)
  return (
    <Box textAlign="center">
      <SocialLinkWrapper
        href="https://www.instagram.com/choicetopay/"
        fluid={data.FooterInstagram.childImageSharp.fluid}
      />
      <SocialLinkWrapper
        href="https://twitter.com/choicetopay"
        fluid={data.FooterTwitter.childImageSharp.fluid}
      />
      <SocialLinkWrapper
        href="https://www.facebook.com/Choicetopay/"
        fluid={data.FooterFacebook.childImageSharp.fluid}
      />
      <SocialLinkWrapper
        href="https://medium.com/choicetopay"
        fluid={data.FooterMedium.childImageSharp.fluid}
      />
    </Box>
  )
}
