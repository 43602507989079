import Img from 'gatsby-image'
import React from 'react'
import {
  Box,
  BoxProps,
  Button,
  Flex,
  FlexProps,
  Text,
  TextProps,
} from 'rebass/styled-components'
import { IconType } from 'react-icons'
import { Separator } from 'reakit/Separator'

// Containers ----------------------------------------

export const Container = (props: FlexProps): React.ReactElement => (
  <Flex flexDirection="column" {...props} />
)
export const Section = (props: FlexProps): React.ReactElement => (
  <Flex as="section" {...props} />
)
export const Masthead = (props: FlexProps): React.ReactElement => (
  <Flex flexDirection="column" bg="primary.main" {...props} />
)

interface SectionBoxProps {
  bg: string
  children: React.ReactNode
}

export const SectionBox = ({
  bg,
  children,
}: SectionBoxProps): React.ReactElement => (
  <Flex bg={bg} flexDirection="column" alignItems="center">
    <Box width={1} maxWidth={['20rem', '45rem', '90rem']} px={[2, 3, 5]} py={4}>
      {children}
    </Box>
  </Flex>
)

// Image ------------------------------------------------------------

export const HeaderIcon = (props: { Icon: IconType }): React.ReactElement => (
  <props.Icon size="2em" />
)
interface SeparatorLineProps extends BoxProps {
  color: string
}
export const SeparatorLine = (
  props: SeparatorLineProps,
): React.ReactElement => {
  const { color = 'dark' } = props
  return (
    <Box
      as={Separator}
      orientation="horizontal"
      m={[2, 4, 4]}
      sx={{ color, backgroundColor: color, borderColor: color }}
      {...props}
    />
  )
}
export const StyledImg = (props: any): React.ReactElement => (
  <Box as={Img} css={{ maxHeight: 128 }} {...props} />
)
export const TextImage = (props: FlexProps): React.ReactElement => (
  <Flex css={{ display: 'inline-block' }} {...props} />
)
export const TextImageVertical = (props: FlexProps): React.ReactElement => (
  <Flex
    css={{ display: 'inline-block', verticalAlign: 'text-bottom' }}
    {...props}
  />
)

// Text --------------------------------------------------------

// MD ---------------------------------------------------------

export const TitleMD = (props: TextProps): React.ReactElement => (
  <Text
    color="primary.text"
    fontSize={[6, 8, 10]}
    fontFamily="body"
    fontWeight="black"
    py={[4, 5, 6]}
    {...props}
  />
)
export const SubtitleMD = (props: TextProps): React.ReactElement => (
  <Text
    color="primary.main"
    pt={[2, 3, 4]}
    fontSize={[3, 3, 6]}
    fontFamily="body"
    fontWeight="black"
    {...props}
  />
)

// HalfWidth -------------------------------------------

export const HalfWidthSmallSubtitle = (
  props: TextProps,
): React.ReactElement => (
  <Text
    py={2}
    as="h4"
    fontWeight="bold"
    fontSize={2}
    fontFamily="body"
    color="primary.main"
    {...props}
  />
)

export const HalfWidthSmallSubtitleBold = (
  props: TextProps,
): React.ReactElement => (
  <Text
    py={2}
    as="h4"
    fontWeight="black"
    fontSize={[2, 2, 3]}
    fontFamily="body"
    color="primary.main"
    {...props}
  />
)
export const HalfWidthSmallSubtitleDark = (
  props: TextProps,
): React.ReactElement => (
  <Text
    py={2}
    as="h4"
    fontSize={2}
    fontWeight="bold"
    fontFamily="body"
    color="dark"
    {...props}
  />
)

export const HalfWidthSmallSubtitleText = (
  props: TextProps,
): React.ReactElement => (
  <Text
    py={2}
    color="primary.text"
    textAlign="start"
    fontSize={[1, 2, 3]}
    fontFamily="Calibre"
    fontWeight="normal"
    {...props}
  />
)

// Header ----------------------------------------------------------

export const HeaderLarge = (props: FlexProps): React.ReactElement => (
  <Flex as="header" flexDirection="column" {...props} />
)
export const HeaderTitle = (props: TextProps): React.ReactElement => (
  <Text
    as="h1"
    fontWeight="bold"
    fontSize={10}
    fontFamily="heading"
    {...props}
  />
)

export const SectionHeader = (props: TextProps): React.ReactElement => (
  <Text
    as="h1"
    fontWeight="bold"
    fontSize={[6, 8, 10]}
    fontFamily="heading"
    pb={[3, 1, 4]}
    {...props}
  />
)

export const Title = (props: TextProps): React.ReactElement => (
  <Text
    as="h1"
    textAlign="center"
    fontFamily="Calibre"
    fontWeight="bold"
    fontSize={12}
    {...props}
  />
)
export const WelcomeTitle = (props: TextProps): React.ReactElement => (
  <Text
    as="h4"
    fontSize={[4, 6, 8]}
    fontWeight="bold"
    fontFamily="body"
    color="primary.text"
    {...props}
  />
)

// Inline ------------------------------------------------

export const InlineText = (props: TextProps): React.ReactElement => (
  <Text
    fontWeight="black"
    fontFamily="body"
    color="primary.text"
    sx={{ display: 'inline-block' }}
    {...props}
  />
)

// Subtitle ---------------------------------------------

export const Subtitle = (props: TextProps): React.ReactElement => (
  <Text
    as="h2"
    fontWeight="bold"
    fontSize={[2, 4, 4]}
    fontFamily="heading"
    color="primary.text"
    {...props}
  />
)

export const SubtitleCenter = (props: TextProps): React.ReactElement => (
  <Text
    as="h2"
    textAlign="center"
    fontWeight="bold"
    fontSize={6}
    fontFamily="body"
    color="primary.text"
    {...props}
  />
)
export const SubtitleMain = (props: TextProps): React.ReactElement => (
  <Text
    as="h1"
    fontWeight="bold"
    fontSize={10}
    fontFamily="Calibre"
    color="primary.main"
    {...props}
  />
)

//  SmallSubTitles -----------------------------------------------

export const SmallSubtitle = (props: TextProps): React.ReactElement => (
  <Text
    as="h4"
    fontWeight="bold"
    fontSize={2}
    fontFamily="Calibre"
    color="primary.text"
    {...props}
  />
)
export const BodyTextLight = (props: TextProps): React.ReactElement => (
  <Text
    fontFamily="body"
    fontSize={[0, 1, 1]}
    lineHeight={['1.2rem', '1.5rem', '1.5rem']}
    fontWeight="light"
    color="darkMuted"
    textAlign="start"
    {...props}
  />
)

// Link ------------------------------------------------------------

export const AccessButton = (props: any): React.ReactElement => (
  <Button
    css={{ borderRadius: 24 }}
    px={4}
    width={1 / 2}
    alignSelf="center"
    bg="primary.main"
    color="primary.text"
    fontWeight="semibold"
    fontSize={0}
    {...props}
  />
)

export const AccessButtonReverse = (props: any): React.ReactElement => (
  <Button
    sx={{ borderRadius: 24 }}
    px={4}
    width={1 / 2}
    alignSelf="center"
    bg="primary.text"
    color="primary.main"
    fontWeight="semibold"
    fontSize={1}
    {...props}
  />
)

export const ExternalLink = (props: TextProps): React.ReactElement => (
  <Text
    as="a"
    color="primary.main"
    css={{ textDecoration: 'none' }}
    {...props}
  />
)
export const ExternalLinkWhite = (props: TextProps): React.ReactElement => (
  <Text as="a" color="primary.text" {...props} />
)

// --------------------------------------------------------

export interface HeaderProps {
  title: string
  Icon: React.ComponentType
}
const HeaderContainer = (props: FlexProps): React.ReactElement => (
  <Flex
    as="header"
    justifyContent="center"
    alignItems="baseline"
    width={1}
    {...props}
  />
)
export const Header = (props: HeaderProps): React.ReactElement => {
  const { Icon, title } = props

  return (
    <HeaderContainer>
      <HeaderIcon Icon={Icon} />
      <HeaderTitle>{title}</HeaderTitle>
    </HeaderContainer>
  )
}
