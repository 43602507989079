import React from 'react'
import { GatsbyLinkProps } from 'gatsby-link'
import { map } from 'ramda'
import { MenuItem, MenuProps } from './Menu'
import { Box, Button, Flex, FlexProps } from 'rebass/styled-components'
import {
  useToolbarState,
  ToolbarState,
  Toolbar,
  ToolbarItem,
} from 'reakit/Toolbar'
import { Button as ReakitButton } from 'reakit/Button'

const HeaderMenuItemContainer = (props: FlexProps): React.ReactElement => (
  <Box css={{ display: 'inline-block' }} {...props} />
)
const HeaderMenuContainer = (props: object): React.ReactElement => (
  <Flex
    flex={'1'}
    flexDirection="row"
    justifyContent="flex-end"
    alignItems="baseline"
    bg={'primary.main'}
    {...props}
  />
)

interface HeaderMenuItemProps {
  isActive: boolean
  menuItem: MenuItem
  toolbar: ToolbarState
  Link: React.ComponentClass<GatsbyLinkProps<any>> | any
}
const HeaderMenuItem = (props: HeaderMenuItemProps): React.ReactElement => {
  const { Link, isActive, menuItem, toolbar } = props
  const { exact, name, path } = menuItem
  return (
    <Link to={path}>
      <HeaderMenuItemContainer>
        <Button
          as={ToolbarItem}
          forwardedAs={ReakitButton}
          {...toolbar}
          sx={{
            color: 'primary.text',
            bg: 'primary.main',
            fontSize: [1, -1, -1],
            px: [1, 2, 2],
            py: [1, 3, 4],
            ml: [0, 0, 1],
            fontFamily: 'body',
            fontWeight: 'bold',
            textAlign: 'center',
            textDecoration: isActive ? 'underline' : 'none',
          }}
        >
          {name}
        </Button>
      </HeaderMenuItemContainer>
    </Link>
  )
}

interface HeaderMenuProps extends MenuProps {}

export function HeaderMenu(props: HeaderMenuProps): React.ReactElement {
  const { menuItems, pathname, Link } = props
  const toolbar = useToolbarState()

  const mapMenuItems = map(
    (menuItem: MenuItem): React.ReactElement => {
      const { exact, path } = menuItem
      const isActive = exact ? pathname === path : pathname.startsWith(path)
      return (
        <HeaderMenuItem
          key={path}
          Link={Link}
          menuItem={menuItem}
          isActive={isActive}
          toolbar={toolbar}
        />
      )
    }
  )
  return (
    <HeaderMenuContainer>
      <Toolbar {...toolbar} aria-label="header menu">
        {mapMenuItems(menuItems)}
      </Toolbar>
    </HeaderMenuContainer>
  )
}

export default HeaderMenu
