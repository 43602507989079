import React from 'react'
import { Text } from 'rebass/styled-components'
import { ExternalLink } from './base'

interface CustomerJoinLinkProps {
  textColor?: string
}
export default function CustomerJoinLink(
  props: CustomerJoinLinkProps,
): React.ReactElement {
  const { textColor } = props
  const linkColor = textColor || 'primary.text'
  return (
    <ExternalLink href={CUSTOMER_JOIN_URL}>
      <Text sx={{ color: linkColor }}>Request Access</Text>
    </ExternalLink>
  )
}

export const CUSTOMER_JOIN_URL = 'https://choicetopay.typeform.com/to/kk62LE'
