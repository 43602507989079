import React from 'react'
import { Link, PageRendererProps } from 'gatsby'
import { createGlobalStyle } from 'styled-components'
import { FaInfoCircle, FaNewspaper } from 'react-icons/fa'
import { Box } from 'rebass/styled-components'
import reset from 'styled-reset'
import useViewportSizes from 'use-viewport-sizes'

import { Footer } from './Footer'
import Header from './Header'
import fonts from '../fonts'
import Sidebar from './Sidebar'
import SidebarMenu from './SidebarMenu'

export const menuItems = [
  { name: 'Using Choice', path: '/using/', exact: true, Icon: FaInfoCircle },
  {
    name: 'Where to use Choice',
    shortName: 'Where',
    path: '/where/',
    exact: false,
    Icon: FaNewspaper,
  },
  {
    name: "I'm a Merchant",
    path: '/merchant/',
    exact: false,
    Icon: FaNewspaper,
  },
  { name: 'Charity', path: '/charity/', exact: false, Icon: FaNewspaper },
  { name: 'Feedback', path: '/feedback/', exact: false, Icon: FaNewspaper },
]

const GlobalStyle = createGlobalStyle`
  ${reset}

  .react-icon {
    vertical-align: middle;
  }

  ${fonts}

`

export interface LayoutProps extends PageRendererProps {
  children: React.ReactNode
}

function Layout(props: LayoutProps): React.ReactElement {
  const { pathname } = props.location

  const [vpWidth, vpHeight] = useViewportSizes()
  const shouldRenderSidebar = vpWidth < 768

  return (
    <>
      <GlobalStyle />
      <Header
        Link={Link}
        pathname={pathname}
        menuItems={menuItems}
        shouldRenderSidebar={shouldRenderSidebar}
      />
      <Box>{props.children}</Box>
      <Footer
        Link={Link}
        pathname={pathname}
        menuItems={menuItems}
        shouldRenderSidebar={shouldRenderSidebar}
      />
      {/* NOTE: (mw) ...Sidebar horror stories...  */}
      <Sidebar shouldRenderSidebar={shouldRenderSidebar}>
        <SidebarMenu Link={Link} pathname={pathname} menuItems={menuItems} />
      </Sidebar>
    </>
  )
}

export default Layout

export const withLayout = <T extends object>(
  WrappedComponent: React.ComponentType<T>
): React.FunctionComponent =>
  function WithLayout(props: T & LayoutProps): React.ReactElement {
    return (
      <Layout {...props}>
        <WrappedComponent {...props} />
      </Layout>
    )
  }
