import React from 'react'
import { Box, BoxProps, Flex, FlexProps, Text } from 'rebass/styled-components'
import { MenuProps } from './Menu'
import { AccessButtonReverse } from './base'
import HeaderMenu from './HeaderMenu'
import { HomeLogoLink } from './HomeLogoLink'
import CustomerJoinLink from './CustomerJoinLink'

const HeaderBackground = (props: FlexProps): React.ReactElement => (
  <Flex bg="primary.main" justifyContent="center" {...props} />
)

const HeaderContainer = (props: FlexProps): React.ReactElement => (
  <Flex
    pt={[0, 3, 4]}
    fontFamily="header"
    width={1}
    maxWidth={['20rem', '45rem', '90rem']}
    sx={{
      flexDirection: 'row',
      alignItems: 'baseline',
    }}
    {...props}
  />
)

const HeaderLogo = (): React.ReactElement => (
  <Box
    width={[5 / 9, 1 / 10, '10rem']}
    sx={{ alignSelf: 'start' }}
    mt={[0, '0.3rem', 0]}
  >
    <HomeLogoLink />
  </Box>
)

export const HeaderJoinMovementButton = (): React.ReactElement => (
  <Box>
    <AccessButtonReverse bg="secondary.main" width={1} fontSize={[1, -2, -2]}>
      <CustomerJoinLink textColor="primary.main" />
    </AccessButtonReverse>
  </Box>
)

interface HeaderProps extends MenuProps {
  shouldRenderSidebar: boolean
}

export function Header(props: HeaderProps): React.ReactElement {
  const { menuItems, pathname, Link, shouldRenderSidebar } = props

  return (
    <HeaderBackground>
      <HeaderContainer>
        <HeaderLogo />
        {!shouldRenderSidebar && (
          <>
            <HeaderMenu menuItems={menuItems} pathname={pathname} Link={Link} />
            <HeaderJoinMovementButton />
          </>
        )}
      </HeaderContainer>
    </HeaderBackground>
  )
}

export default Header
