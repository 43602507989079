import { Link } from 'gatsby'
import React from 'react'
import { MenuItem, MenuProps } from './Menu'
import { Box, Flex, Text } from 'rebass/styled-components'
import { FaHeart } from 'react-icons/fa'
import { AccessButton, Container, SectionBox, SeparatorLine } from './base'
import { SocialLinkBadges } from './SocialLinks'
import CustomerJoinLink from './CustomerJoinLink'

const FooterLink = (props: any): React.ReactElement => (
  <Text color="primary.text" as={Link} {...props} />
)

const PageLinks = (props: any): React.ReactElement => (
  <Text
    py={1}
    fontWeight="semibold"
    color="primary.text"
    as={Link}
    sx={{ textDecoration: 'none' }}
    {...props}
  />
)

function FooterTextContainer(props: object): React.ReactElement {
  return (
    <Text textAlign="left" fontSize={[-1, 0, -1]} px={[3, 4, 0]} {...props} />
  )
}

function FooterContainer(props: object): React.ReactElement {
  return (
    <Box
      as="footer"
      color="primary.text"
      bg="primary.main"
      fontFamily="body"
      {...props}
    />
  )
}
function FullScreenFooter(): React.ReactElement {
  return (
    <Flex mt={4}>
      <Container px={2} width={[0, 1 / 2, 1 / 3]}>
        <PageLinks to="/">Home</PageLinks>
        <PageLinks to="/using">Using Choice</PageLinks>
      </Container>
      <Container px={2} width={[0, 1 / 2, 1 / 3]}>
        <PageLinks to="/charity">Charity</PageLinks>
        <PageLinks to="/where">Where to use Choice</PageLinks>
      </Container>
      <Container px={2} width={[0, 1 / 2, 1 / 3]}>
        <PageLinks to="/merchant">I'm a Merchant</PageLinks>
        <PageLinks to="/feedback">Feedback</PageLinks>
      </Container>
      <Container ml={[0, 2, 0]} width={[0, '76%', 2 / 5]}>
        <AccessButton my={[0, 2, 2]} bg="seondary.main" width={1} fontSize={1}>
          <CustomerJoinLink textColor="primary.main" />
        </AccessButton>
      </Container>
      <Container px={0} width={1}>
        <Box sx={{ alignContent: 'center' }}>
          <SocialLinkBadges />
        </Box>
      </Container>
    </Flex>
  )
}

function FooterText(): React.ReactElement {
  return (
    <Box pb={4}>
      <FooterTextContainer>
        Choice is a registered financial services provider FSP643289 and a
        member of the FSCL dispute resolution scheme 7719.
      </FooterTextContainer>
      <FooterTextContainer>
        © 2019 Choice Limited. All rights reserved. Please take a moment to read
        the Choice&nbsp;
        <FooterLink to="/terms">
          User Terms of Service & Privacy Policy.
        </FooterLink>
      </FooterTextContainer>
      <FooterTextContainer>
        Click here for Choice&nbsp;
        <FooterLink to="/merchant-terms">Merchant Terms of Service.</FooterLink>
      </FooterTextContainer>
      <FooterTextContainer>
        Powered with <FaHeart /> by Gatsby 2.0
      </FooterTextContainer>
    </Box>
  )
}

interface FooterProps extends MenuProps {
  shouldRenderSidebar: boolean
}

export function Footer(props: FooterProps): React.ReactElement {
  return (
    <SectionBox bg="primary.main">
      <FooterContainer>
        <FooterMenu {...props} />
        <SeparatorLine color="white" />
        <FooterText />
      </FooterContainer>
    </SectionBox>
  )
}

interface FooterMenuProps extends MenuProps {
  shouldRenderSidebar: boolean
}

function FooterMenu(props: FooterMenuProps): React.ReactElement {
  const { menuItems, pathname, Link, shouldRenderSidebar } = props

  const isActive = (item: MenuItem) =>
    item.exact ? pathname === item.path : pathname.startsWith(item.path)

  if (shouldRenderSidebar) {
    return (
      <>
        <Box
          ml={3}
          pt={4}
          my={3}
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)',
          }}
        >
          <PageLinks to="/">Home</PageLinks>
          {menuItems.map(menuItem => {
            const { path, name, shortName } = menuItem
            return (
              <PageLinks
                key={path}
                to={path}
                sx={{
                  textDecoration: isActive(menuItem) ? 'underline' : 'none',
                }}
              >
                {shortName || name}
              </PageLinks>
            )
          })}
        </Box>
        <Container width={1} mt={[0, 2, 0]}>
          <AccessButton
            my={[3, 2, 2]}
            bg="secondary.main"
            width={[5 / 7, 1 / 2, 1 / 5]}
          >
            <CustomerJoinLink textColor="primary.main" />
          </AccessButton>
          <Box sx={{ alignContent: 'end' }}>
            <SocialLinkBadges />
          </Box>
        </Container>
      </>
    )
  }

  return (
    <Flex mt={4}>
      <Box
        width={1}
        sx={{
          display: 'grid',
          gridTemplateColumns: [
            'repeat(0, 0fr)',
            'repeat(2, 1fr)',
            'repeat(3, 1fr)',
          ],
        }}
      >
        <PageLinks to="/">Home</PageLinks>
        {menuItems.map(menuItem => {
          const { path, name, shortName } = menuItem
          return (
            <PageLinks
              key={path}
              to={path}
              sx={{ textDecoration: isActive(menuItem) ? 'underline' : 'none' }}
            >
              {shortName || name}
            </PageLinks>
          )
        })}
      </Box>
      <Container width={[1, 3 / 5, 3 / 5]}>
        <AccessButton my={[0, 2, 2]} bg="secondary.main" width={1}>
          <CustomerJoinLink textColor="primary.main" />
        </AccessButton>
      </Container>
      <Container px={0} width={1}>
        <Box sx={{ alignContent: 'center' }}>
          <SocialLinkBadges />
        </Box>
      </Container>
    </Flex>
  )
}
